const CustomLoader = () => {
  return (
    <>
      <div id="page-loader">
        <div className="spinner spinner--l"></div>
      </div>
    </>
  );
};
export default CustomLoader;
