import React from "react";
import Admin from "../pages/Admin";
function HomeContainer(props) {
  return (
    <>
      <Admin {...props}  />
    </>
  );
}

export default HomeContainer;
