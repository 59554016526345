export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const SUCCESS_MESSAGE = "SUCCESS_MESSAGE";
export const ERROR_MESSAGE= "ERROR_MESSAGE";
export const WARNING_MESSAGE= "WARNING_MESSAGE";
export const INFO_MESSAGE= "INFO_MESSAGE";
export const USER_LOGOUT="USER_LOGOUT";
export const SET_TRANSPORT_RENT="SET_TRANSPORT_RENT";
export const SET_SINGLE_TRANS_RENT="SET_SINGLE_TRANS_RENT";
export const SET_PARTY="SET_PARTY";
export const SET_BUY="SET_BUY";
export const SET_SELL="SET_SELL";
export const SET_ACCOUNT_LIST="SET_ACCOUNT_LIST";
export const SET_ACCOUNT_DETAILS="SET_ACCOUNT_DETAILS";
export const SET_ITEM_LIST="SET_ITEM_LIST";
export const SET_GODOWN_LIST="SET_GODOWN_LIST";
export const SET_STOCK_DETAILS="SET_STOCK_DETAILS";
export const SET_PARTY_HISTORY="SET_PARTY_HISTORY";
export const SET_PENDING="SET_PENDING";
export const SET_LOADED="SET_LOADED";
export const SET_SINGLE_PARTY="SET_SINGLE_PARTY";
export const SET_LOGO="SET_LOGO";
export const SET_SINGLE_STOCK="SET_SINGLE_STOCK";
export const SET_SINGLE_ACCOUNT="SET_SINGLE_ACCOUNT";
export const SET_USER_DATA="SET_USER_DATA";
export const SET_FIRM_LIST="SET_FIRM_LIST";
export const SET_EMPTY_TRANS_LIST="SET_EMPTY_TRANS_LIST";
export const SET_ALL_PARTY_HISTORY="SET_ALL_PARTY_HISTORY";
export const SET_INVESTMENT_LIST="SET_INVESTMENT_LIST";
export const SET_ALL_BUYSELL="SET_ALL_BUYSELL";
export const SET_TOTAL_DATA = "SET_TOTAL_DATA";
export const SET_STOCK_LIST = "SET_STOCK_LIST";






